import { connect } from "react-redux";
import React from "react";
import { NavLink } from "react-router-dom";
import {} from "react-router-dom";
import { Container, Navbar, Nav, Form, Button } from "react-bootstrap";

import NavCategories from "../components/NavCategories";
import PlaceSearchInput from "../components/PlaceSearchInput";

import SettingsButton from "../components/SettingsButton";
import Setting from "../utils/Setting";
import Translation from "../components/Translation";
import Translations from "../utils/Translations";
import { updateBreadcrumb } from "./actions/BreadcrumbActions";
import Utils from "../utils/Utils";

import "../components/Header.sass";

class OwnProps {
  updateBreadcrumb: any;
}

class State {}

class Header extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  form: any;
  query: any;

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
    this.form = React.createRef();
    this.query = React.createRef();
    this.redirect = this.redirect.bind(this);
  }

  redirect(event: any) {
    event.preventDefault();
    const query = this.query.current.state.value;

    if (query.length > 0) {
      const redirectURL = "/recherche/" + query;
      location.href = redirectURL;
    }
  }

  onNavLinkClick() {
    this.props.updateBreadcrumb();
    Utils.closeNavigation();
    Utils.animateTop();
  }

  onClickBtn() {
    Utils.animateTop();
    this.props.updateBreadcrumb();
  }

  render() {
    return (
      <div className="header">
        <Navbar
          className="navbar navbar-expand-md navbar-dark fixed-top bg-dark"
          expand="lg"
        >
          <Container>
            <Navbar.Brand href="/">
              {Setting.getFromIdentifier("logo") ? (
                <img
                  src={Setting.getFromIdentifier("logo")}
                  className="img-fluid logo"
                  alt={Translations.getFromIdentifier("header:site-name")}
                />
              ) : (
                Translations.getFromIdentifier("header:site-name")
              )}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <NavLink
                  onClick={this.onNavLinkClick.bind(this)}
                  className="nav-link d-block d-sm-none"
                  to="/"
                >
                  <Translation identifier="header:home" />
                </NavLink>
                <NavCategories />

                <NavLink
                  to="/about"
                  onClick={this.onNavLinkClick.bind(this)}
                  className="nav-link"
                  activeClassName="selected"
                >
                  <Translation identifier="header:about" />
                </NavLink>

                <SettingsButton type="navlink" />
              </Nav>
              <Form ref={this.form} inline onSubmit={this.redirect}>
                <PlaceSearchInput ref={this.query} />

                <Button variant="outline-success" onClick={this.redirect}>
                  <Translation identifier="header:search" />
                </Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapActionsToProps = {
  updateBreadcrumb: updateBreadcrumb
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Header);
Header;
