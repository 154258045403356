import React from "react";
import Cookies from "../utils/Cookies";
import { Container } from "react-bootstrap";
import SettingsButton from "../components/SettingsButton";
import Session from "../utils/Session";

import "../components/PositionInfo.sass";

class OwnProps {}

class State {
  show = true;
}

class PositionInfo extends React.Component<OwnProps, State> {
  static COOKIE_KEY = "position-info";
  static COOKIE_VALUE = "yes";

  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();

    this.handleHide = this.handleHide.bind(this);
  }

  handleHide() {
    this.setState({ show: false });
    Cookies.set(PositionInfo.COOKIE_KEY, PositionInfo.COOKIE_VALUE, 365);
  }

  componentWillMount() {
    if (Cookies.get(PositionInfo.COOKIE_KEY) === PositionInfo.COOKIE_VALUE) {
      this.setState({ show: false });
    }

    // this.setState({ show: true });
  }

  render() {
    if (this.state.show) {
      const positionElements = Session.getPositionElements();

      return (
        <div className="position-info">
          <div className="alert alert-info text-center mt-3" role="alert">
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={this.handleHide}
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <p>
              {positionElements.message}
              <strong>
                <SettingsButton label={positionElements.position} type="link" />
              </strong>
            </p>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default PositionInfo;
