const initialState = {
  nodes: []
};

const BreadcrumbReducer = (state = initialState, action: any) => {
  const newState = { ...state };

  if (action.type === "BREADCRUMB_SET") {
    newState.nodes = action.payload.nodes;
  }

  return newState;
};

export default BreadcrumbReducer;
