import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import NotificationNav from "../components/NotificationNav";
import RestClient from "../utils/RestClient";
import HomeCategories from "../components/HomeCategories";
import HomeCarousel from "../components/HomeCarousel";
import Geolocation from "../components/Geolocation";
import Meta from "../utils/Meta";
import PositionInfo from "../components/PositionInfo";
import RedirectBrowser from "../components/RedirectBrowser";

import Translations from "../utils/Translations";

import "../pages/HomePage.sass";

class OwnProps {
  categories?: [] = [];
}

class State {
  data?: { response: any } = undefined;
  loading: boolean = false;
  status: boolean = false;
  show: boolean = false;
  errors: [] = [];
}

class HomePage extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async refreshData() {
    this.setState({ loading: true });
    let data = await this.client.get("/rest/category/", {
      home: "true"
    });
    let status: boolean = false;
    let errors = [];

    if (data && data.status) {
      if (data.status == 200) {
        status = true;
      } else {
        if (data.response.errors) {
          errors = data.response.errors;
        }
      }
    } else {
      errors = ["404"];
    }

    this.setState({ data, status, errors, loading: false });
  }

  componentDidMount() {
    this.refreshData();
    Meta.setTitle(Translations.getFromIdentifier("home-page:title"));
  }

  componentDidUpdate() {}

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    if (this.state.loading) {
      return <div className="home-page" />;
    } else {
      if (
        this.state.data &&
        this.state.data.response &&
        this.state.data.response.categories
      ) {
        return (
          <div className="home-page">
            <RedirectBrowser />
            <Container>
              <Row>
                <Col className="col-xl-3 col-lg-3 col-sm-12 col-12">
                  <NotificationNav />
                  <PositionInfo />
                </Col>
                <Col className="col-xl-9 col-lg-9 col-sm-12 col-12">
                  <HomeCarousel />
                </Col>
              </Row>
            </Container>

            <Geolocation />
            <hr />
            <HomeCategories />
          </div>
        );
      } else {
        if (!this.state.status) {
          return this.client.renderErrors(this.state.errors);
        }
      }
    }
  }
}

export default HomePage;
