import RestClient from "../utils/RestClient";
import Session from "../utils/Session";
import { isUndefined } from "util";

class Translations {
  static DEVICE = "web";
  static data: any;
  static notTranslated: [] = [];
  static ready: boolean = false;
  static client: RestClient;

  static async init() {
    this.client = new RestClient();
    const data = await this.client.get(Session.getLanguage().url, {
      device: Translations.DEVICE
    });

    if (data && data.status == 200) {
      Translations.setData(data);
    }
    return data;
  }

  static setData(data: any) {
    Translations.data = data;
    Translations.ready = true;
    document.body.classList.add("translations-ready");
  }

  static setUntranslated(identifier: string) {
    const _this = this;

    setTimeout(function() {
      console.log(`Set translation [${identifier}]`);
      const data = _this.client.post(
        "/rest/language/set/",
        {},
        { identifier: identifier, device: Translations.DEVICE }
      );
    }, 5000);
  }

  static getData() {
    return Translations.data;
  }

  static getFromIdentifier(identifier: string) {
    let translation = undefined;

    if (Translations.data && Translations.data.response) {
      translation = Translations.data.response.translations[identifier];
    }

    if (translation) {
      return translation;
    } else {
      if (isUndefined(translation)) {
        Translations.setUntranslated(identifier);
      }

      return "[" + identifier + "]";
    }
  }
}

export default Translations;
