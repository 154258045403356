import React from "react";

import {
  Alert,
  Tab,
  Tabs,
  Row,
  Col,
  Container,
  Jumbotron,
  Spinner
} from "react-bootstrap";

import { NavLink } from "react-router-dom";

import Meta from "../utils/Meta";
import PlacesTable from "../components/PlacesTable";
import PlacesMap from "../components/PlacesMap";
import SubcategoryContent from "../components/SubcategoryContent";
import RestClient from "../utils/RestClient";
import Translation from "../components/Translation";
import Translations from "../utils/Translations";
import Utils from "../utils/Utils";

import "../pages/SubcategoryPage.sass";

class OwnProps {
  match?: any = undefined;
}

class State {
  data?: { response: any } = undefined;
  subcategoryId?: number = 0;
  loading: boolean = false;
  status: boolean = false;
  errors: [] = [];
}

class SubcategoryPage extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
  }

  async refreshPage(page: any, ordering: string) {
    const subcategoryId = this.state.subcategoryId || 0;
    this.refreshData(subcategoryId, page, ordering);
  }

  async refreshData(
    subcategoryId: number,
    page: number = 1,
    ordering: string = ""
  ) {
    this.setState({ loading: true });

    let data = await this.client.get(
      "/rest/subcategory/" + subcategoryId + "/",
      {
        page: page,
        ordering: ordering
      }
    );
    let status: boolean = false;
    let errors = [];

    if (data && data.status) {
      if (data.status == 200) {
        status = true;
      } else {
        if (data.response.errors) {
          errors = data.response.errors;
        }
      }
    } else {
      errors = ["404"];
    }

    this.setState({ data, subcategoryId, status, errors, loading: false });
  }

  componentDidMount() {
    let subcategoryId = this.props.match.params.id;
    this.refreshData(subcategoryId);
  }

  componentDidUpdate() {
    if (
      this.state.subcategoryId &&
      this.props.match.params.id &&
      !this.state.loading
    ) {
      if (this.props.match.params.id != this.state.subcategoryId) {
        let subcategoryId = this.props.match.params.id;
        this.refreshData(subcategoryId);
      }
    }
  }

  render() {
    let subcategory;

    if (this.state.loading) {
      return (
        <div className="subcategory-page">
          <Container>
            <Jumbotron className="text-center loading">
              <Spinner animation="border" role="status">
                <span className="sr-only">...</span>
              </Spinner>
            </Jumbotron>
          </Container>
        </div>
      );
    } else {
      if (
        this.state.data &&
        this.state.data.response &&
        this.state.data.response.subcategory
      ) {
        let subcategory = this.state.data.response.subcategory;
        const places: any = this.state.data.response.results.items;
        const navigation = this.state.data.response.results.navigation;
        const position = this.state.data.response.session.point_obj;
        const zoom = this.state.data.response.results.zoom;

        Meta.setTitle(subcategory.title);

        const jumbotronStyle = {
          backgroundColor: subcategory.category__category_color,
          backgroundImage: "url(" + subcategory.image + ")"
        };

        let inner;

        if (places) {
          inner = (
            <>
              <Tabs defaultActiveKey="content" id="subcategory-page">
                <Tab
                  eventKey="content"
                  title={Translations.getFromIdentifier(
                    "subcategory-page:tab-informations"
                  )}
                >
                  <div className="inner p-3">
                    <SubcategoryContent subcategory={subcategory} />
                  </div>
                </Tab>

                <Tab
                  eventKey="map"
                  title={Translations.getFromIdentifier(
                    "subcategory-page:tab-map"
                  )}
                >
                  <div className="subcategory-page-places mt-3">
                    <Row>
                      <Col className="col-xl-5 col-lg-5 col-sm-12 col-12">
                        <PlacesMap
                          places={places}
                          zoom={zoom}
                          position={position}
                        />
                      </Col>
                      <Col className="col-xl-7 col-lg-7 col-sm-12 col-12">
                        <PlacesTable
                          parent={this}
                          navigation={navigation}
                          places={places}
                        />
                      </Col>
                    </Row>
                  </div>
                </Tab>
              </Tabs>
            </>
          );
        } else {
          inner = (
            <>
              <Alert variant="warning">
                <Translation identifier="subcategory-page:empty-info" />
              </Alert>
            </>
          );
        }

        return (
          <div className="subcategory-page">
            <Container>
              <Jumbotron style={jumbotronStyle}>
                <h1 className={subcategory.text_color}>{subcategory.title}</h1>

                <h2>
                  <NavLink
                    onClick={Utils.animateTop}
                    className=""
                    to={subcategory.category__public_url}
                  >
                    <img
                      alt={subcategory.category__title}
                      src={subcategory.category__logo_svg_white}
                    />
                    {subcategory.category__title}
                  </NavLink>
                </h2>
              </Jumbotron>
              {inner}
            </Container>
          </div>
        );
      } else {
        if (!this.state.status) {
          return this.client.renderErrors(this.state.errors);
        }
      }
    }
  }
}

export default SubcategoryPage;
