import React from "react";
import { Button } from "react-bootstrap";

import RestClient from "../utils/RestClient";

import "../components/TrackPhone.sass";

class OwnProps {
  stripped: string = "";
  complete: string = "";
  url: string = "";
}

class State {
  show = false;
}

class TrackPhone extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  modal: any = null;
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();

    this.state = {
      show: false
    };

    this.handleShow = this.handleShow.bind(this);
  }

  async handleShow() {
    await this.client.get(this.props.url, {});
    this.setState({ show: true });
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      <div>
        {this.state.show ? (
          <div>{this.props.complete}</div>
        ) : (
          <Button
            className="track-phone"
            variant="primary"
            size="sm"
            onClick={this.handleShow}
          >
            {this.props.stripped}
          </Button>
        )}
      </div>
    );
  }
}

export default TrackPhone;
