import React from "react";
import { NavDropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import RestClient from "../utils/RestClient";
import Translations from "../utils/Translations";
import Utils from "../utils/Utils";
import { updateBreadcrumb } from "./actions/BreadcrumbActions";

import "../components/NavCategories.sass";

class OwnProps {
  updateBreadcrumb: any;
  categories?: [] = [];
}

class State {
  data?: { response: any } = undefined;
  loading: boolean = false;
  status: boolean = false;
  errors: [] = [];
}

class NavCategories extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
    this.clickEvent = this.clickEvent.bind(this);
  }

  async refreshData() {
    this.setState({ loading: true });
    let data = await this.client.get("/rest/category/", {});
    let status: boolean = false;
    let errors = [];

    if (data && data.status) {
      if (data.status == 200) {
        status = true;
      } else {
        if (data.response.errors) {
          errors = data.response.errors;
        }
      }
    } else {
      errors = ["404"];
    }

    this.setState({ data, status, errors, loading: false });
  }

  componentDidMount() {
    this.refreshData();
  }

  clickEvent() {
    this.props.updateBreadcrumb();
    Utils.animateTop();
    Utils.closeNavigation();
    document.body.click();
  }

  render() {
    let categories;

    if (this.state.loading) {
      return (
        <div className="nav-categories dropdown nav-item">
          <NavDropdown
            title={Translations.getFromIdentifier("nav-categories:title")}
            id="basic-nav-dropdown2"
          ></NavDropdown>
        </div>
      );
    } else {
      if (
        this.state.data &&
        this.state.data.response &&
        this.state.data.response.categories
      ) {
        categories = this.state.data.response.categories;

        return (
          <div className="nav-categories dropdown nav-item">
            <NavDropdown
              title={Translations.getFromIdentifier("nav-categories:title")}
              id="basic-nav-dropdown2"
            >
              {categories.map((category: any, i: any) => {
                return (
                  <NavLink
                    key={i}
                    role="button"
                    className="dropdown-item"
                    activeClassName="selected"
                    onClick={this.clickEvent}
                    to={category.public_url}
                  >
                    {category.title}
                  </NavLink>
                );
              })}
            </NavDropdown>
          </div>
        );
      } else {
        if (!this.state.status) {
          return this.client.renderErrors(this.state.errors);
        }
      }
    }
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapActionsToProps = {
  updateBreadcrumb: updateBreadcrumb
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(NavCategories);
NavCategories;
