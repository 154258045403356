export function updateBreadcrumb() {
  return {
    type: "BREADCRUMB_SET",
    payload: {}
  };
}

export function setNodes() {
  return {
    type: "BREADCRUMB_SET",
    payload: {}
  };
}
