import React from "react";
import { Marker } from "react-google-maps";
import PlaceModal from "../components/PlaceModal";

import "../pages/ContactPage.sass";

class OwnProps {
  marker: any = null;
}

class State {}

class MapMarker extends React.Component<OwnProps, State> {
  modal: any = null;
  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
    this.handleClick = this.handleClick.bind(this);
    this.modal = React.createRef();
  }

  handleClick() {
    this.modal.current.show(this.props.marker.place);
  }

  render() {
    return (
      <>
        <Marker
          title={this.props.marker.title}
          icon={this.props.marker.icon}
          onClick={this.handleClick}
          position={this.props.marker.position}
        />
        <PlaceModal ref={this.modal} place={this.props.marker.place} />
      </>
    );
  }
}

export default MapMarker;
