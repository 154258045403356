import RestClient from "../utils/RestClient";

class Setting {
  static data: any;
  static ready: boolean = false;
  static client: RestClient;

  static async init() {
    this.client = new RestClient();
    const data = await this.client.get("/rest/setting/", {
      pathname: location.pathname
    });

    if (data && data.status == 200) {
      Setting.setData(data);
    }
    return data;
  }

  static setData(data: any) {
    Setting.data = data;
    Setting.ready = true;
    document.body.classList.add("setting-ready");
  }

  static getData() {
    return Setting.data;
  }

  static getColor(identifier: string) {
    return Setting.getFromIdentifier("colors")[identifier];
  }

  static getStores(identifier: string) {
    return Setting.getFromIdentifier("stores")[identifier];
  }

  static getImage(identifier: string) {
    return Setting.getFromIdentifier("images")[identifier];
  }

  static getSvg(identifier: string) {
    return Setting.getFromIdentifier("svg")[identifier];
  }

  static getTimestamp() {
    return Setting.getFromIdentifier("timestamp");
  }

  static getFromIdentifier(identifier: string) {
    let setting = undefined;

    if (Setting.data && Setting.data.response) {
      setting = Setting.data.response.setting[identifier];
    }

    return setting;
  }
}

export default Setting;
