import React from "react";
import "../components/InvalidAppVersion.sass";
import AppVersion from "../utils/AppVersion";

class OwnProps {}

class State {}

class InvalidAppVersion extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    const appversion = AppVersion.VERSION;

    return (
      <div className="invalid-app-version">
        La version <strong>{appversion}</strong> n'est plus suportée.
      </div>
    );
  }
}

export default InvalidAppVersion;
