import { connect } from "react-redux";
import React from "react";

import { Link } from "react-router-dom";

import { updateBreadcrumb } from "./actions/BreadcrumbActions";
import { Carousel, Spinner } from "react-bootstrap";
import RestClient from "../utils/RestClient";
import Colors from "../utils/Colors";

import Utils from "../utils/Utils";
import NavLink from "react-bootstrap/NavLink";

import "../components/HomeCarousel.sass";

class OwnProps {
  categories?: [] = [];
  updateBreadcrumb: any;
}

class State {
  data?: { response: any } = undefined;
  loading: boolean = false;
  status: boolean = false;
  errors: [] = [];
}

class HomeCarousel extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;
  modal: any;

  constructor(props: OwnProps) {
    super(props);
    this.modal = React.createRef();
    this.client = new RestClient();
    this.state = new State();
  }

  async refreshData() {
    this.setState({ loading: true });
    let data = await this.client.get("/rest/carousel/", {
      home: "true"
    });
    let status: boolean = false;
    let errors = [];

    if (data && data.status) {
      if (data.status == 200) {
        status = true;
      } else {
        if (data.response.errors) {
          errors = data.response.errors;
        }
      }
    } else {
      errors = [];
    }

    this.setState({ data, status, errors, loading: false });
  }

  componentDidMount() {
    this.refreshData();
  }

  onNavlinkClicked() {
    this.props.updateBreadcrumb();
    Utils.animateTop();
  }

  render() {
    let items;

    if (this.state.loading) {
      return (
        <div className="home-carousel loading">
          <Spinner animation="border" role="status">
            <span className="sr-only">...</span>
          </Spinner>
        </div>
      );
    } else {
      if (
        this.state.data &&
        this.state.data.response &&
        this.state.data.response.items
      ) {
        items = this.state.data.response.items;

        return (
          <div className="home-carousel">
            <Carousel>
              {items.map((item: any, i: any) => {
                const backgroundColor = item.color ? item.color : Colors.dark;
                return (
                  <Carousel.Item key={i}>
                    <Link
                      onClick={this.onNavlinkClicked.bind(this)}
                      to={item.public_url}
                    >
                      <img
                        style={{ cursor: "pointer" }}
                        className="d-block w-100 rounded"
                        src={item.image_slim}
                        alt={item.title}
                      />
                    </Link>

                    <Carousel.Caption
                      style={{ backgroundColor: backgroundColor }}
                    >
                      <h3>{item.title}</h3>
                      <p>{item.intro}</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        );
      } else {
        if (!this.state.status) {
          return this.client.renderErrors(this.state.errors);
        }
      }
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    breadcrumb: state.breadcrumb
  };
};

const mapActionsToProps = {
  updateBreadcrumb: updateBreadcrumb
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(HomeCarousel);
HomeCarousel;
