import React from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { NavLink } from "react-router-dom";
import Setting from "../utils/Setting";
import { updateBreadcrumb } from "./actions/BreadcrumbActions";
import Utils from "../utils/Utils";

import "./Breadcrumb.sass";

class OwnProps {
  updateBreadcrumb: any;
  breadcrumb?: any;
}

class State {
  pathname?: string;
}

class BreadcrumbComponent extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
  }

  componentDidMount() {
    /* just in case */
    setInterval(() => {
      if (location.pathname != this.state.pathname) {
        this.setState({ pathname: location.pathname });
      }
    }, 5000);

    window.addEventListener("locationchange", function() {
      // console.log("location changed!");
    });
  }

  onNavlinkClicked() {
    this.props.updateBreadcrumb();
    Utils.animateTop();
  }

  render() {
    const breadcrumbs = Setting.getFromIdentifier("breadcrumbs");
    const search = "/recherche";
    var query = location.pathname;

    if (query.search(search) === 0) {
      query = search;
    }

    let nodes = breadcrumbs[query];

    /*
    nodes = [
      { url: "/", label: "Accueil", active: false },
      { url: "/1/", label: "Categorie 1", active: true }
    ];
    */

    if (nodes && nodes.length > 1) {
      return (
        <Container className="breadcrumb-component">
          <Breadcrumb>
            {nodes.map((node: any, i: any) => {
              if (node.active) {
                return (
                  <Breadcrumb.Item key={i} active>
                    {node.label}
                  </Breadcrumb.Item>
                );
              } else {
                return (
                  <li className="breadcrumb-item" key={i}>
                    <NavLink
                      onClick={this.onNavlinkClicked.bind(this)}
                      to={node.url}
                    >
                      {node.label}
                    </NavLink>
                  </li>
                );
              }
            })}
          </Breadcrumb>
        </Container>
      );
    } else {
      return <></>;
    }
  }
}

//export default BreadcrumbComponent;

const mapStateToProps = (state: any) => {
  return {
    breadcrumb: state.breadcrumb
  };
};

const mapActionsToProps = {
  updateBreadcrumb: updateBreadcrumb
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(BreadcrumbComponent);
BreadcrumbComponent;
