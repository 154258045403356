import React from "react";
import RestClient from "../utils/RestClient";

class OwnProps {
  value?: number;
}

class State {
  data?: { response: any } = undefined;
  loading: boolean = false;
  value: any;
  status: boolean = false;
  errors: [] = [];
}

class LanguagesDropdown extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  static data: {};
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
    this.handleChange = this.handleChange.bind(this);
  }

  async refreshData() {
    this.setState({ loading: true });
    const data = await this.client.get("/rest/language/");

    if (data && data.status == 200) {
      this.setState({ data, loading: false });
      LanguagesDropdown.data = data;
    } else {
      alert("Error: language");
    }
  }

  componentDidMount() {
    this.setState({ value: this.props.value });
    this.refreshData();
  }

  componentDidUpdate() {}

  handleChange(event: any) {
    this.setState({ value: event.target.value });
  }

  render() {
    let languages;

    if (this.state.loading) {
      return "";
    } else {
      if (
        this.state.data &&
        this.state.data.response &&
        this.state.data.response.languages
      ) {
        languages = this.state.data.response.languages;

        return (
          <>
            <select
              onChange={this.handleChange}
              name="language"
              value={this.state.value}
              className="form-control"
            >
              {languages.map((language: any) => {
                return (
                  <option value={language.id} key={language.id}>
                    {language.title}
                  </option>
                );
              })}
            </select>
          </>
        );
      } else {
        if (!this.state.status) {
          return this.client.renderErrors(this.state.errors);
        }
      }
    }
  }
}

export default LanguagesDropdown;
