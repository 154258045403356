import React from "react";
import Parser from "html-react-parser";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import Link from "../components/Link";
import Translation from "../components/Translation";

class OwnProps {
  subcategory?: any = undefined;
}

class State {}

class SubcategoryContent extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
  }

  render() {
    const subcategory = this.props.subcategory;

    return (
      <>
        {subcategory.content ? (
          Parser(subcategory.content)
        ) : (
          <Translation identifier="subcategorypage:empty_content" />
        )}

        {subcategory.blocks.length > 0 ? (
          <Tab.Container
            id="subcategory_tab"
            defaultActiveKey={subcategory.blocks[0].identifier}
          >
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  {subcategory.blocks.map((block: any, i: any) => {
                    return (
                      <Nav.Item key={i}>
                        <Nav.Link eventKey={block.identifier}>
                          {block.title}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  {subcategory.blocks.map((block: any, i: any) => {
                    return (
                      <Tab.Pane eventKey={block.identifier} key={i}>
                        {Parser(block.content)}
                      </Tab.Pane>
                    );
                  })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        ) : (
          ""
        )}

        {subcategory.links.length > 0 && (
          <>
            <hr />

            {subcategory.links.length == 1 && (
              <h5>
                <Translation identifier="subcategorypage:link" />
              </h5>
            )}

            {subcategory.links.length > 1 && (
              <h5>
                <Translation identifier="subcategorypage:links" />
              </h5>
            )}

            <Row>
              {subcategory.links.map((link: any, i: any) => {
                return (
                  <Col className="col-xl-4 col-lg-4 col-sm-6 col-12" key={i}>
                    <Link link={link} />
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </>
    );
  }
}

export default SubcategoryContent;
