import { connect } from "react-redux";
import React from "react";
import RestClient from "../utils/RestClient";
import { Row, Col } from "react-bootstrap";

import Utils from "../utils/Utils";
import { NavLink } from "react-router-dom";
import { updateBreadcrumb } from "../components/actions/BreadcrumbActions";

import "../components/Category.sass";

class OwnProps {
  category: any;
  updateBreadcrumb: any;
}

class State {}

class Category extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
  }

  onNavlinkClicked() {
    this.props.updateBreadcrumb();
    Utils.animateTop();
  }

  render() {
    const category = this.props.category;

    return (
      <div
        className="category"
        style={{ backgroundColor: category.category_color }}
      >
        <NavLink
          onClick={this.onNavlinkClicked.bind(this)}
          to={category.public_url}
        >
          <Row>
            <Col className="col-xl-4 col-lg-4 col-sm-4 col-4 col-left">
              <img src={category.logo_svg_white} alt={category.title} />
            </Col>
            <Col className="col-xl-8 col-lg-8 col-sm-8 col-8 col-right">
              <NavLink
                onClick={this.onNavlinkClicked.bind(this)}
                to={category.public_url}
              >
                {category.title}
              </NavLink>
            </Col>
          </Row>
        </NavLink>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapActionsToProps = {
  updateBreadcrumb: updateBreadcrumb
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Category);
Category;
