import React from "react";
import Parser from "html-react-parser";
import { Container, Jumbotron, Spinner } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

import Link from "../components/Link";
import Meta from "../utils/Meta";
import Place from "../components/Place";
import RestClient from "../utils/RestClient";
import Subcategory from "../components/Subcategory";
import Translation from "../components/Translation";

import "../pages/NotificationPage.sass";

class OwnProps {
  match?: any = undefined;
}

class State {
  data?: { response: any } = undefined;
  notificationId?: number = 0;
  loading: boolean = false;
  status: boolean = false;
  errors: [] = [];
}

class NotificationPage extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
  }

  async refreshPage(page: any, ordering: string) {
    const notificationId = this.state.notificationId || 0;
    this.refreshData(notificationId);
  }

  async refreshData(notificationId: number) {
    this.setState({ loading: true });

    let data = await this.client.get(
      "/rest/notification/" + notificationId + "/"
    );
    let status: boolean = false;
    let errors = [];

    if (data && data.status) {
      if (data.status == 200) {
        status = true;
      } else {
        if (data.response.errors) {
          errors = data.response.errors;
        }
      }
    } else {
      errors = ["404"];
    }

    this.setState({ data, notificationId, status, errors, loading: false });
  }

  componentDidMount() {
    let notificationId = this.props.match.params.id;
    this.refreshData(notificationId);
  }

  componentDidUpdate() {
    if (
      this.state.notificationId &&
      this.props.match.params.id &&
      !this.state.loading
    ) {
      if (this.props.match.params.id != this.state.notificationId) {
        let notificationId = this.props.match.params.id;
        this.refreshData(notificationId);
      }
    }
  }

  render() {
    let notification;
    console.log(this.state);

    if (this.state.loading) {
      return (
        <div className="notification-page">
          <Container>
            <Jumbotron className="text-center loading">
              <Spinner animation="border" role="status">
                <span className="sr-only">...</span>
              </Spinner>
            </Jumbotron>
          </Container>
        </div>
      );
    } else {
      if (
        this.state.data &&
        this.state.data.response &&
        this.state.data.response.notification
      ) {
        notification = this.state.data.response.notification;

        Meta.setTitle(notification.title);

        const jumbotronStyle = {
          backgroundColor: notification.color,
          backgroundImage: "url(" + notification.image_slim + ")"
        };

        return (
          <div className="notification-page">
            <Container>
              <Jumbotron style={jumbotronStyle}>
                {notification.type.svg && (
                  <img
                    className="type"
                    src={notification.type.svg}
                    alt={notification.type.label}
                  />
                )}

                <h1>{notification.title}</h1>
                {notification.intro && <h2>{notification.intro}</h2>}
              </Jumbotron>

              {Parser(notification.content)}
              {notification.places.length > 0 ? (
                <div className="places">
                  <hr />
                  <h5>
                    {notification.places.length > 1 && (
                      <Translation identifier="notificationpage:places" />
                    )}

                    {notification.places.length == 1 && (
                      <Translation identifier="notificationpage:place" />
                    )}
                  </h5>
                  <Row>
                    {notification.places.map((place: any, i: any) => {
                      return (
                        <Col
                          className="col-xl-4 col-lg-4 col-sm-6 col-12"
                          key={i}
                        >
                          <Place place={place} key={i} />
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              ) : (
                ""
              )}

              {notification.subcategories.length > 0 ? (
                <div className="subcategories">
                  <hr />

                  <h5>
                    {notification.subcategories.length > 1 && (
                      <Translation identifier="notificationpage:subcategories" />
                    )}
                    {notification.subcategories.length == 1 && (
                      <Translation identifier="notificationpage:subcategory" />
                    )}
                  </h5>
                  <Row>
                    {notification.subcategories.map(
                      (subcategory: any, i: any) => {
                        return (
                          <Col
                            key={i}
                            className="col-xl-4 col-lg-4 col-sm-6 col-12"
                          >
                            <Subcategory subcategory={subcategory} />
                          </Col>
                        );
                      }
                    )}
                  </Row>
                </div>
              ) : (
                ""
              )}

              {notification.links.length > 0 ? (
                <div className="links">
                  <hr />
                  <h5>
                    {notification.links.length > 1 && (
                      <Translation identifier="notificationpage:links" />
                    )}
                    {notification.links.length == 1 && (
                      <Translation identifier="notificationpage:links" />
                    )}
                  </h5>
                  <Row>
                    {notification.links.map((link: any, i: any) => {
                      return (
                        <Col
                          key={i}
                          className="col-xl-4 col-lg-4 col-sm-6 col-12"
                        >
                          <Link link={link} />
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              ) : (
                ""
              )}
            </Container>
          </div>
        );
      } else {
        if (!this.state.status) {
          return this.client.renderErrors(this.state.errors);
        } else {
          return null;
        }
      }
    }
  }
}

export default NotificationPage;
