import React from "react";
import Session from "../utils/Session";
import RestClient from "../utils/RestClient";

class OwnProps {}

class State {}

export default class extends React.Component<OwnProps, State> {
  interval: any;
  timeout: any;

  render() {
    return null;
  }

  updatePosition() {
    if (Session.getIsPosition() && navigator.geolocation) {
      Session.position = navigator.geolocation.watchPosition(
        (position: any) => {
          const point =
            position.coords.latitude + "," + position.coords.longitude;
          const postData = { is_position: true, point };
          new RestClient().post("/rest/update-position/", {}, postData);
        }
      );
    }
  }

  componentDidMount() {
    /* with interval */
    /*
    const intervalMiliseconds = Setting.getFromIdentifier(
      "settings_geolocation_miliseconds"
    );

    if (intervalMiliseconds >= 1000) {
      this.interval = setInterval(
        this.updatePosition.bind(this),
        intervalMiliseconds
      );
    }
    */

    /* once with timeout */
    this.timeout = setTimeout(this.updatePosition.bind(this), 3000);
  }
}
