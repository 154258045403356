import RestClient from "../utils/RestClient";
import Cookies from "../utils/Cookies";
import Setting from "../utils/Setting";
import Translations from "../utils/Translations";

class Session {
  static IDENTIFIER: string = "geomig-session";

  static data: any;
  static ready: boolean = false;
  static client: RestClient;
  static position: any;

  static async init() {
    this.client = new RestClient();

    const data = await this.client.get(Session.getUrl());

    if (data && data.response && data.response.session) {
      Session.setData(data);
      Cookies.set(Session.IDENTIFIER, data.response.session.identifier, 30);
    }

    return data;
  }

  static getUrl() {
    let sessionUrl = "/rest/session/";
    const sessionIdentifier = Cookies.get(Session.IDENTIFIER);

    if (sessionIdentifier) {
      sessionUrl += sessionIdentifier + "/";
    }

    return sessionUrl;
  }

  static setData(data: any) {
    Session.data = data;
    Session.ready = true;
    document.body.classList.add("session-ready");
  }

  static getData() {
    return Session.data;
  }

  static getLanguage() {
    return Session.data.response.session.language;
  }

  static getCity() {
    return Session.data.response.session.city;
  }

  static getPosition() {
    return Session.data.response.session.point_obj;
  }

  static getIsPosition() {
    return Session.data.response.session.is_position;
  }

  static getIdentifier() {
    return Cookies.get(Session.IDENTIFIER);
  }

  static getPositionElements() {
    const isPosition = Session.getIsPosition();
    let message;
    let position;
    const defaultCity = Setting.getFromIdentifier("default_city");

    if (isPosition) {
      const sessionPosition = Session.getPosition();
      message = Translations.getFromIdentifier("session:position_true_text");

      if (sessionPosition && sessionPosition.title) {
        position = sessionPosition.title;
      } else {
        position = defaultCity ? defaultCity.title : "n/d";
      }
    } else {
      message = Translations.getFromIdentifier("session:position_false_text");
      const sessionCity = Session.getCity();
      if (sessionCity && sessionCity.title) {
        position = sessionCity.title;
      } else {
        position = defaultCity ? defaultCity.title : "n/d";
      }
    }

    return { position, message };
  }
}

export default Session;
