import React from "react";

import {
  Alert,
  Row,
  Col,
  Container,
  Jumbotron,
  Spinner
} from "react-bootstrap";

import PlacesTable from "../components/PlacesTable";
import PlacesMap from "../components/PlacesMap";
import Meta from "../utils/Meta";
import RestClient from "../utils/RestClient";
import Translation from "../components/Translation";

class OwnProps {
  match?: any = undefined;
}

class State {
  data?: { response: any } = undefined;
  page: number = 1;
  loading: boolean = false;
  status: boolean = false;
  query: string = "";
  errors: [] = [];
}

class SearchPage extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
  }

  async refreshPage(page: number, ordering: string) {
    this.refreshData(this.state.query, page, ordering);
  }

  async refreshData(query: string, page: number = 1, ordering: string = "") {
    this.setState({ loading: true });

    let data = await this.client.get("/rest/place/search/", {
      query: query,
      page: page,
      ordering: ordering
    });

    let status: boolean = false;
    let errors = [];

    if (data && data.status) {
      if (data.status == 200) {
        status = true;
      } else {
        if (data.response.errors) {
          errors = data.response.errors;
        }
      }
    } else {
      errors = [];
    }

    this.setState({ data, query, status, errors, loading: false });
  }

  componentDidMount() {
    let query = this.props.match.params.query;
    this.refreshData(query);
    Meta.setTitle(query);
  }

  componentDidUpdate() {}

  render() {
    if (this.state.loading) {
      return (
        <div className="search-page">
          <Container>
            <Jumbotron className="text-center loading">
              <Spinner animation="border" role="status">
                <span className="sr-only">...</span>
              </Spinner>
            </Jumbotron>
          </Container>
        </div>
      );
    } else {
      if (this.state.data && this.state.data.response) {
        const places: any = this.state.data.response.results.items;
        const position = this.state.data.response.session.point_obj;
        const zoom = this.state.data.response.results.zoom;

        let inner;

        if (places && places.length > 0) {
          const navigation = this.state.data.response.results.navigation;

          inner = (
            <>
              <div className="search-page-places">
                <Row>
                  <Col className="col-xl-5 col-lg-5 col-sm-12 col-12">
                    <PlacesMap
                      places={places}
                      zoom={zoom}
                      position={position}
                    />
                  </Col>
                  <Col className="col-xl-7 col-lg-7 col-sm-12 col-12">
                    <PlacesTable
                      parent={this}
                      navigation={navigation}
                      places={places}
                    />
                  </Col>
                </Row>
              </div>
            </>
          );
        } else {
          inner = (
            <>
              <Alert variant="warning">
                <Translation identifier="search-page:empty-info" />
              </Alert>
            </>
          );
        }

        return (
          <div className="search-page">
            <Container>{inner}</Container>
          </div>
        );
      } else {
        if (!this.state.status) {
          return this.client.renderErrors(this.state.errors);
        }
      }
    }
  }
}

export default SearchPage;
