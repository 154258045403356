import { connect } from "react-redux";
import React from "react";

import {
  Alert,
  Tab,
  Tabs,
  Row,
  Col,
  Container,
  Jumbotron,
  Spinner
} from "react-bootstrap";

import Meta from "../utils/Meta";
import PlacesTable from "../components/PlacesTable";
import PlacesMap from "../components/PlacesMap";
import CategoryContent from "../components/CategoryContent";
import RestClient from "../utils/RestClient";
import Utils from "../utils/Utils";
import Subcategory from "../components/Subcategory";
import Translation from "../components/Translation";
import Translations from "../utils/Translations";
import { updateBreadcrumb } from "../components/actions/BreadcrumbActions";

import "../pages/CategoryPage.sass";

class OwnProps {
  updateBreadcrumb: any;
  match?: any = undefined;
}

class State {
  data?: { response: any } = undefined;
  categoryId?: number = 0;
  loading: boolean = false;
  status: boolean = false;
  errors: [] = [];
}

class CategoryPage extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
  }

  async refreshPage(page: any, ordering: string) {
    const categoryId = this.state.categoryId || 0;
    this.refreshData(categoryId, page, ordering);
  }

  async refreshData(
    categoryId: number,
    page: number = 1,
    ordering: string = ""
  ) {
    this.setState({ loading: true });

    let data = await this.client.get("/rest/category/" + categoryId + "/", {
      page: page,
      ordering: ordering
    });
    let status: boolean = false;
    let errors = [];

    if (data && data.status) {
      if (data.status == 200) {
        status = true;
      } else {
        if (data.response && data.response.errors) {
          errors = data.response.errors;
        }
      }
    } else {
      errors = ["404"];
    }

    this.setState({ data, categoryId, status, errors, loading: false });
  }

  componentDidMount() {
    let categoryId = this.props.match.params.id;
    this.refreshData(categoryId);
  }

  componentDidUpdate() {
    if (
      this.state.categoryId &&
      this.props.match.params.id &&
      !this.state.loading
    ) {
      if (this.props.match.params.id != this.state.categoryId) {
        let categoryId = this.props.match.params.id;
        this.refreshData(categoryId);
      }
    }
  }

  onNavlinkClicked() {
    this.props.updateBreadcrumb();
    Utils.animateTop();
  }

  render() {
    let category;

    if (this.state.loading) {
      return (
        <div className="category-page">
          <Container>
            <Jumbotron className="text-center loading">
              <Spinner animation="border" role="status">
                <span className="sr-only">...</span>
              </Spinner>
            </Jumbotron>
          </Container>
        </div>
      );
    } else {
      if (
        this.state.data &&
        this.state.data.response &&
        this.state.data.response.category
      ) {
        category = this.state.data.response.category;
        const places: any = this.state.data.response.results.items;
        const navigation = this.state.data.response.results.navigation;
        const position = this.state.data.response.session.point_obj;
        const zoom = this.state.data.response.results.zoom;

        Meta.setTitle(category.title);

        const jumbotronStyle = {
          backgroundImage: "url(" + category.logo_svg_white + ")",
          backgroundRepeat: "no-repeat",
          backgroundColor: category.category_color
        };

        let inner;

        if (places && places.length > 0) {
          inner = (
            <>
              <Tabs defaultActiveKey="content" id="category-page">
                <Tab
                  eventKey="content"
                  title={Translations.getFromIdentifier(
                    "category-page:tab-informations"
                  )}
                >
                  <div className="inner p-3">
                    <CategoryContent category={category} />
                  </div>
                </Tab>

                <Tab
                  eventKey="map"
                  title={Translations.getFromIdentifier(
                    "category-page:tab-map"
                  )}
                >
                  <div className="category-page-places mt-3">
                    <Row>
                      <Col className="col-xl-5 col-lg-5 col-sm-12 col-12">
                        <PlacesMap
                          places={places}
                          zoom={zoom}
                          position={position}
                        />
                      </Col>
                      <Col className="col-xl-7 col-lg-7 col-sm-12 col-12">
                        <PlacesTable
                          parent={this}
                          navigation={navigation}
                          places={places}
                        />
                      </Col>
                    </Row>
                  </div>
                </Tab>
                <Tab
                  eventKey="subcategories"
                  title={Translations.getFromIdentifier(
                    "category-page:tab-subcategories"
                  )}
                >
                  <div className="category-page-subcategories mt-3">
                    <Row>
                      {category.subcategories.map(
                        (subcategory: any, key: any) => {
                          return (
                            <Col
                              className="col-xl-4 col-lg-6 col-sm-6 col-12"
                              key={key}
                            >
                              <Subcategory subcategory={subcategory} />
                            </Col>
                          );
                        }
                      )}
                    </Row>
                  </div>
                </Tab>
              </Tabs>
            </>
          );
        } else {
          inner = (
            <>
              <Alert variant="warning">
                <Translation identifier="category-page:empty-info" />
              </Alert>
            </>
          );
        }

        return (
          <div className="category-page">
            <Container>
              <Jumbotron style={jumbotronStyle}>
                <h1>{category.title}</h1>
                {category.intro && <h2>{category.intro}</h2>}
              </Jumbotron>
              {inner}
            </Container>
          </div>
        );
      } else {
        if (!this.state.status) {
          return this.client.renderErrors(this.state.errors);
        }
      }
    }
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapActionsToProps = {
  updateBreadcrumb: updateBreadcrumb
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(CategoryPage);
CategoryPage;
