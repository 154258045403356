import React from "react";
import RestClient from "../utils/RestClient";

class OwnProps {
  value?: number;
}

class State {
  data?: { response: any } = undefined;
  loading: boolean = false;
  status: boolean = false;
  value: any;
  errors: [] = [];
}

class CitiesDropdown extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  static data: {};
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
    this.handleChange = this.handleChange.bind(this);
  }

  async refreshData() {
    this.setState({ loading: true });
    const data = await this.client.get("/rest/city/");

    if (data && data.status == 200) {
      this.setState({ data, loading: false });
      CitiesDropdown.data = data;
    } else {
      alert("Error: city");
    }
  }

  componentDidMount() {
    this.setState({ value: this.props.value });
    this.refreshData();
  }

  componentDidUpdate() {}

  handleChange(event: any) {
    this.setState({ value: event.target.value });
  }

  render() {
    let cities;

    if (this.state.loading) {
      return "";
    } else {
      if (
        this.state.data &&
        this.state.data.response &&
        this.state.data.response.cities
      ) {
        cities = this.state.data.response.cities;

        return (
          <>
            <select
              onChange={this.handleChange}
              name="city"
              value={this.state.value}
              className="form-control"
            >
              {cities.map((city: any) => {
                return (
                  <option value={city.id} key={city.id}>
                    {city.title}
                  </option>
                );
              })}
            </select>
          </>
        );
      } else {
        if (!this.state.status) {
          return this.client.renderErrors(this.state.errors);
        }
      }
    }
  }
}

export default CitiesDropdown;
