import React from "react";
import { FormControl } from "react-bootstrap";
import RestClient from "../utils/RestClient";
import Translations from "../utils/Translations";
import Autosuggest from "react-autosuggest";
import "../components/PlaceSearchInput.sass";

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion: any) => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion: any) => (
  <div key={suggestion.place_id}>
    {suggestion.name}
    <div className="pull-right">
      <small>({suggestion.distance})</small>
    </div>
  </div>
);

class OwnProps {}

class State {
  value?: any = "";
  suggestions: any = [];
}

class PlaceSearchInput extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
    this.client = new RestClient();
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(
      this
    );
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(
      this
    );
  }
  onChange(event: any, data: any) {
    const newValue = data.newValue;
    this.setState({
      value: newValue
    });
  }

  async onSuggestionsFetchRequested(inputValue: any) {
    const { value } = inputValue;

    const data = await this.client.get("/rest/place/autocomplete/", {
      query: value
    });

    if (data && data.status == 200) {
      this.setState({ suggestions: data.response.places });
    }
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    let value, suggestions;

    ({ value, suggestions } = this.state);

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: Translations.getFromIdentifier(
        "place-search-input:placeholder"
      ),
      value,
      onChange: this.onChange
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

export default PlaceSearchInput;
