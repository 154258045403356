import React from "react";
import {
  Alert,
  Button,
  Container,
  Modal,
  Row,
  Col,
  Form
} from "react-bootstrap";

import Translation from "./Translation";
import RestClient from "../utils/RestClient";
import Session from "../utils/Session";
import CitiesDropdown from "./CitiesDropdown";
import LanguagesDropdown from "./LanguagesDropdown";

import "../components/SettingsModal.sass";

class OwnProps {}

class State {
  isPosition?: boolean = false;
  language?: number;
  city?: number;

  positionActivated?: boolean;
  positionLoading?: boolean = false;
  positionError?: any;
  position: any;
  data: any;
  status: any;
  errors: any;
  show: boolean = false;
}

class SettingsModal extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  city: any;
  language: any;
  client: RestClient;
  navigatorPosition: any;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
    this.city = React.createRef();
    this.language = React.createRef();
    this.handleClose = this.handleClose.bind(this);
    this.handleDisconnect = this.handleDisconnect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputPosition = this.handleInputPosition.bind(this);
  }

  getURL() {
    return "/rest/session/" + Session.getIdentifier() + "/";
  }

  getDisconnectURL() {
    return "/rest/session/" + Session.getIdentifier() + "/disconnect/";
  }

  async refreshData() {
    let data = await this.client.get(this.getURL(), {});

    if (data && data.status && data.status == 200) {
      const isPosition = data.response.session.is_position;
      const language = data.response.session.language.id;
      const city = data.response.session.city
        ? data.response.session.city.id
        : "";

      this._setPosition(isPosition);

      this.setState({
        data,
        isPosition,
        language,
        city,
        status,
        show: true
      });
    } else {
      console.error("setting modal post response", data);
    }
  }

  private _setPosition(isPosition: boolean) {
    if (isPosition) {
      this.setState({ positionLoading: true });

      navigator.geolocation.clearWatch(Session.position);

      if (navigator.geolocation) {
        this.navigatorPosition = navigator.geolocation.getCurrentPosition(
          (position: any) => {
            this.setState({
              positionLoading: false,
              positionActivated: true,
              position: position
            });
          },
          (error: any) => {
            this.setState({
              positionLoading: false,
              positionActivated: false,
              positionError: error
            });
          }
        );
      } else {
        this.setState({
          positionLoading: false,
          positionActivated: false,
          position: null
        });
      }
    }

    this.setState({ isPosition });
  }

  private _renderPositionAlert() {
    if (this.state.isPosition) {
      if (this.state.positionLoading) {
        return (
          <div className="settings-modal-position">
            <Alert variant="warning">
              <Translation identifier="settings-modal:position-loading" />
            </Alert>
          </div>
        );
      } else {
        if (this.state.position && this.state.positionActivated) {
          return (
            <div className="settings-modal-position">
              <Alert variant="success">
                <Translation identifier="settings-modal:position-success" />
              </Alert>
            </div>
          );
        } else {
          let message;
          if (this.state.positionError) {
            message = this.state.positionError.message;
          } else {
            message = "";
          }

          return (
            <div className="settings-modal-position">
              <Alert variant="danger">
                <Translation identifier="settings-modal:position-error" />
                <hr />
                {message}
              </Alert>
            </div>
          );
        }
      }
    } else {
      return (
        <div className="settings-modal-position">
          <Alert variant="info">
            <Translation identifier="settings-modal:position-not-shared" />
          </Alert>
        </div>
      );
    }
  }

  _renderForm() {
    return (
      <Container>
        <Alert variant="info">
          <Translation identifier="settings-modal:info" />
        </Alert>

        <Form>
          <Row>
            <Col>
              <div className="form-group">
                <label>
                  <Translation identifier="settings-modal:languages-label" />
                </label>
                <LanguagesDropdown
                  ref={this.language}
                  value={this.state.language}
                />
              </div>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <div className="form-check">
                <input
                  onChange={this.handleInputPosition}
                  checked={this.state.isPosition}
                  className="form-check-input"
                  id="id_is_position"
                  type="checkbox"
                  name="is_position"
                />

                <label className="form-check-label" htmlFor="id_is_position">
                  <Translation identifier="settings-modal:is-position-label" />
                </label>

                {this._renderPositionAlert()}
              </div>
            </Col>

            <Col>
              {!this.state.isPosition ? (
                <div className="form-group">
                  <label>
                    <Translation identifier="settings-modal:city-label" />
                  </label>
                  <CitiesDropdown ref={this.city} value={this.state.city} />
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Row>
            <Col className="text-right">
              <Button variant="link" size="sm" onClick={this.handleDisconnect}>
                <Translation identifier="settings-button:modal-disconnect" />
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }

  handleClose() {
    this.setState({ show: false });
  }

  async handleDisconnect() {
    const data = await this.client.get(this.getDisconnectURL(), {});

    if (data && data.status === 204) {
      this.setState({ show: false });
      location.reload();
    } else {
      console.error("settings modal error", data);
    }
  }

  async handleSubmit() {
    const postData = {
      city: this.city.current ? this.city.current.state.value : null,
      language: this.language.current.state.value,
      is_position: this.state.isPosition,
      position: this.state.position
        ? this.state.position.coords.latitude +
          "," +
          this.state.position.coords.longitude
        : null
    };

    const data = await this.client.post(this.getURL(), {}, postData);

    if (data && data.status === 202) {
      this.setState({ show: false });
      location.reload();
    } else {
      console.error(data.response.errors);
    }
  }

  handleInputPosition(event: any) {
    const target = event.target;
    const isPosition: boolean =
      target.type === "checkbox" ? target.checked : target.value;

    this._setPosition(isPosition);
  }

  render() {
    if (this.state.show) {
      const btnDistabled =
        (this.state.positionLoading && this.state.isPosition) ||
        this.state.positionError;

      return (
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Translation identifier="settings-modal:title" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="settings-modal-body">{this._renderForm()}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              <Translation identifier="settings-button:modal-close" />
            </Button>
            <Button
              disabled={btnDistabled}
              variant="primary"
              onClick={this.handleSubmit}
            >
              <Translation identifier="settings-button:modal-save" />
            </Button>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return "";
    }
  }
}

export default SettingsModal;
