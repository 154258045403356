import Setting from "../utils/Setting";

class Meta {
  static setTitle(prefix: string = "") {
    const suffix = Setting.getFromIdentifier("site_title");
    document.title = `${prefix} - ${suffix}`;
  }
}

export default Meta;
