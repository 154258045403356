import React from "react";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import AcceptCookies from "../components/AcceptCookies";
import { updateBreadcrumb } from "./actions/BreadcrumbActions";
import Translation from "../components/Translation";
import Utils from "../utils/Utils";

import "../components/Footer.sass";

class OwnProps {
  onApiRequest: any;
  updateBreadcrumb: any;
}

class State {}

class Footer extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
  }

  onClickBtn() {
    Utils.animateTop();
    this.props.updateBreadcrumb();
  }

  render() {
    return (
      <Container className="p-3 footer">
        <hr />
        <footer className="mt-3 d-flex justify-content-center">
          <ul className="list-group list-group-horizontal">
            <li className="list-group-item text-center">
              <NavLink
                to="/about"
                onClick={this.onClickBtn.bind(this)}
                activeClassName="selected"
              >
                <Translation identifier="footer:about" />
              </NavLink>
            </li>
            <li className="list-group-item text-center">
              <NavLink
                onClick={this.onClickBtn.bind(this)}
                to="/privacy"
                activeClassName="selected"
              >
                <Translation identifier="footer:privacy" />
              </NavLink>
            </li>
            <li className="list-group-item text-center">
              <NavLink
                to="/contact"
                onClick={this.onClickBtn.bind(this)}
                activeClassName="selected"
              >
                <Translation identifier="footer:contact" />
              </NavLink>
            </li>
          </ul>
        </footer>
        <AcceptCookies />
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapActionsToProps = {
  updateBreadcrumb: updateBreadcrumb
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Footer);
Footer;
