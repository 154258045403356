import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import RestClient from "../utils/RestClient";
import HomeCategory from "../components/HomeCategory";

import "../components/HomeCategories.sass";

class OwnProps {
  categories?: [] = [];
}

class State {
  data?: { response: any } = undefined;
  loading: boolean = false;
  status: boolean = false;
  errors: [] = [];
}

class HomeCategories extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
  }

  async refreshData() {
    this.setState({ loading: true });
    let data = await this.client.get("/rest/category/", {
      home: "true"
    });
    let status: boolean = false;
    let errors = [];

    if (data && data.status) {
      if (data.status == 200) {
        status = true;
      } else {
        if (data.response.errors) {
          errors = data.response.errors;
        }
      }
    } else {
      errors = ["404"];
    }

    this.setState({ data, status, errors, loading: false });
  }

  componentDidMount() {
    this.refreshData();
  }

  componentDidUpdate() {}

  render() {
    let categories;

    if (this.state.loading) {
      return <div className="home-categories" />;
    } else {
      if (
        this.state.data &&
        this.state.data.response &&
        this.state.data.response.categories
      ) {
        categories = this.state.data.response.categories;

        return (
          <div className="home-categories">
            <Container>
              <Row>
                {categories.map((category: any, i: any) => {
                  return (
                    <Col className="col-xl-3 col-lg-4 col-sm-6 col-12" key={i}>
                      <HomeCategory category={category} />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </div>
        );
      } else {
        if (!this.state.status) {
          return this.client.renderErrors(this.state.errors);
        }
      }
    }
  }
}

export default HomeCategories;
