import $ from "jquery";

export default class Utils {
  static animateTop = () => {
    window.scrollTo(0, 0);
  };
  static closeNavigation = () => {
    const navbarToggler = $(".navbar-toggler.collapsed");

    if (navbarToggler.length > 0) {
      navbarToggler.click();
    }
  };
}
