import React from "react";
import RestClient from "../utils/RestClient";
import Translation from "../components/Translation";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-solid-svg-icons";

import "../components/TrackWebsite.sass";

library.add(faMap);

class OwnProps {
  url: string = "";
  href: string = "";
  label: string = "";
  icon: boolean = false;
}

class State {}

class TrackWebsite extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  modal: any = null;
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();

    this.handleClick = this.handleClick.bind(this);
  }

  async handleClick() {
    await this.client.get(this.props.url, {});
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      <a
        target="_blank"
        className="track-website btn btn-sm btn-primary"
        onClick={this.handleClick}
        href={this.props.href}
      >
        {this.props.icon ? <FontAwesomeIcon className="mr-1" icon="map" /> : ""}

        {this.props.label === "" ? (
          <Translation identifier="track-website:href" />
        ) : (
          this.props.label
        )}
      </a>
    );
  }
}

export default TrackWebsite;
