import React from "react";
import { Alert } from "react-bootstrap";

import Map from "../components/Map";
import Setting from "../utils/Setting";
import Session from "../utils/Session";
import SettingsButton from "../components/SettingsButton";

import "./PlacesMap.sass";

class OwnProps {
  places: any;
  position: any;
  zoom: any;
}

class State {}

class PlacesTable extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
  }
  render() {
    let markers = [];

    for (let place of this.props.places) {
      if (place.position && place.name) {
        markers.push({
          title: place.name,
          icon: place.google_pin_small_url,
          position: place.position,
          place: place
        });
      }
    }

    const positionElements = Session.getPositionElements();
    const message = `${positionElements.message} ${positionElements.position}.`;

    return (
      <div className="places-map">
        <Alert variant="info">
          <img
            className="float-left pin-position-info mr-2"
            alt={message}
            title={message}
            src={Setting.getFromIdentifier("google_pin_position")}
          />
          <SettingsButton type="link" label={positionElements.position} />
          <div className="clearfix" />
        </Alert>
        <Map
          zoom={this.props.zoom}
          markers={markers}
          showHome={true}
          position={this.props.position}
        />
      </div>
    );
  }
}

export default PlacesTable;
