import { connect } from "react-redux";
import React from "react";
import RestClient from "../utils/RestClient";
import { Row, Col } from "react-bootstrap";

import Colors from "../utils/Colors";
import PlaceModal from "../components/PlaceModal";
import { updateBreadcrumb } from "../components/actions/BreadcrumbActions";

import "../components/Place.sass";

class OwnProps {
  place: any;
  updateBreadcrumb: any;
}

class State {
  show = false;
}

class Place extends React.Component<OwnProps, State> {
  modal: any = null;
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.modal = React.createRef();
    this.client = new RestClient();
    this.state = new State();

    this.state = {
      show: false
    };

    this.handleShow = this.handleShow.bind(this);
  }

  handleShow() {
    this.modal.current.show(this.props.place);
  }

  render() {
    const place = this.props.place;

    const color = place.color ? place.color : Colors.primary;

    return (
      <>
        <div
          className="place"
          style={{ backgroundColor: color, borderColor: color }}
          onClick={this.handleShow}
        >
          <Row>
            <Col className="col-xl-4 col-lg-4 col-sm-4 col-4 col-left">
              <img alt={place.name} src={place.photo.thumbnail_square} />
            </Col>
            <Col className="col-xl-8 col-lg-8 col-sm-8 col-8 col-right">
              <div className="place_name">{place.name}</div>
              <div className="subcategory_title">
                {place.subcategory && place.subcategory.title}
              </div>
              <div className="distance">
                <small>>{place.distance}</small>
              </div>
            </Col>
          </Row>
        </div>
        <PlaceModal ref={this.modal} place={this.props.place} />
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapActionsToProps = {
  updateBreadcrumb: updateBreadcrumb
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Place);
Place;
