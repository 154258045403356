const initialState = {
  point: null,
  displayPoint: []
};

const GeolocationReducer = (state = initialState, action: any) => {
  const newState = { ...state };

  if (action.type === "POINT_SET") {
    newState.point = action.payload.point;
    newState.displayPoint = action.payload.displayPoint;
  }

  return newState;
};

export default GeolocationReducer;
