import { connect } from "react-redux";
import React from "react";
import RestClient from "../utils/RestClient";
import { Row, Col } from "react-bootstrap";

import { updateBreadcrumb } from "../components/actions/BreadcrumbActions";

import "../components/Link.sass";
import Colors from "../utils/Colors";

class OwnProps {
  link: any;
  updateBreadcrumb: any;
}

class State {}

class Link extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
  }

  async onLinkClicked() {
    await this.client.get(this.props.link.track, {});
  }

  render() {
    const link = this.props.link;

    return (
      <div
        className="link"
        style={{
          backgroundColor: Colors.primary,
          borderdColor: Colors.primary
        }}
      >
        <a
          href={link.url}
          target="_blank"
          onClick={this.onLinkClicked.bind(this)}
        >
          {link.image_square && (
            <Row>
              <Col className="col-xl-4 col-lg-4 col-sm-4 col-4 col-left">
                <img src={link.image_square} alt={link.title} />
              </Col>
              <Col className="col-xl-8 col-lg-8 col-sm-8 col-8 col-right">
                {link.title}
              </Col>
            </Row>
          )}

          {!link.image_square && <span className="single">{link.title}</span>}
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapActionsToProps = {
  updateBreadcrumb: updateBreadcrumb
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Link);
Link;
