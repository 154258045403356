import React from "react";
import Session from "../utils/Session";
import AppVersion from "../utils/AppVersion";

class RestClient {
  API_BASE_URL = "http://localhost:8000";
  //API_BASE_URL = "https://www.geomig.com";

  getAbsoluteURL(url: String) {
    const w: any = window as any;
    let baseUrl;

    if (w.SERVER_DATA && w.SERVER_DATA.API_BASE_URL) {
      baseUrl = w.SERVER_DATA.API_BASE_URL;
    } else {
      baseUrl = this.API_BASE_URL;
    }

    const absoluteUrl = baseUrl + url;
    return absoluteUrl;
  }

  renderLoading() {
    return <div className="loading">Chargement...</div>;
  }

  renderErrors(errors: []) {
    if (errors.length) {
      console.warn("errors", errors);
    }

    return (
      <div className="container">
        <div className="alert alert-danger">Une erreur s'est produite.</div>
      </div>
    );
  }

  getAuthorization(url: string) {
    let absoluteUrl = this.getAbsoluteURL(url);
    const identifier = Session.getIdentifier();
    var myHeaders = new Headers();
    myHeaders.set(
      "Authorization",
      AppVersion.NAME + " " + AppVersion.IDENTIFIER + " " + identifier
    );

    var myInit = {
      method: "GET",
      headers: myHeaders,
      mode: "cors",
      cache: "default"
    };

    var myRequest = new Request(absoluteUrl, {
      method: "GET",
      headers: myHeaders
    });

    return myRequest;
  }

  static encodeParams(params: any) {
    return Object.keys(params)
      .map(function(key: any) {
        return [key, params[key]].map(encodeURIComponent).join("=");
      })
      .join("&");
  }

  async post(url: String, params: any = {}, postFields: {}) {
    let status: boolean = false;
    let absoluteUrl = this.getAbsoluteURL(url);
    let data = null;

    var myHeaders = new Headers();

    const identifier = Session.getIdentifier();

    params.authorization =
      AppVersion.NAME + " " + AppVersion.IDENTIFIER + " " + identifier;

    let paramsEncoded = RestClient.encodeParams(params);
    absoluteUrl += "?" + paramsEncoded;

    try {
      const response = await fetch(absoluteUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(postFields)
      });
      return await response.json();
    } catch (error) {
      console.error("rest client error on post", error);
      return null;
    }
  }

  async get(url: String, params: any = {}) {
    let status: boolean = false;
    let absoluteUrl = this.getAbsoluteURL(url);
    let data = null;

    var myHeaders = new Headers();

    const identifier = Session.getIdentifier();

    params.authorization =
      AppVersion.NAME + " " + AppVersion.IDENTIFIER + " " + identifier;

    let paramsEncoded = RestClient.encodeParams(params);
    absoluteUrl += "?" + paramsEncoded;

    try {
      const response = await fetch(absoluteUrl);
      return await response.json();
    } catch (error) {
      console.log("rest client on get", error);
      return null;
    }
  }
}

export default RestClient;
