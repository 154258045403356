import React from "react";
import { Container, Jumbotron } from "react-bootstrap";

import Meta from "../utils/Meta";
import Setting from "../utils/Setting";
import Translation from "../components/Translation";
import Translations from "../utils/Translations";

import "../pages/ContactPage.sass";

class OwnProps {}

class State {}

class ContactPage extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
  }

  componentDidMount() {
    Meta.setTitle(Translations.getFromIdentifier("contact-page:title"));
  }

  render() {
    const jumbotronStyle = {
      backgroundImage: "url(" + Setting.getImage("static_contact") + ")",
      backgroundColor: Setting.getColor("static_default_btn_bg_color_1")
    };

    return (
      <Container className="contact-page">
        <Jumbotron style={jumbotronStyle}>
          <h1>
            <Translation identifier="contact-page:title" />
          </h1>
          <h2>
            <Translation identifier="contact-page:intro" />
          </h2>
        </Jumbotron>

        <Translation identifier="contact-page:content" />

        {Setting.getFromIdentifier("forms")["is_contact_form"] && (
          <div>
            <hr />
            <a
              target="_blank"
              href={Setting.getFromIdentifier("forms")["contact_form_url"]}
            >
              <Translation identifier="contact-page:fontact-form" />
            </a>
          </div>
        )}
      </Container>
    );
  }
}

export default ContactPage;
