import React from "react";
import Cookies from "../utils/Cookies";
import Translation from "../components/Translation";
import "../components/AcceptCookies.sass";

class OwnProps {}

class State {
  show = true;
}

class AcceptCookies extends React.Component<OwnProps, State> {
  static COOKIE_KEY = "accept-cookies";
  static COOKIE_VALUE = "yes";

  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();

    this.handleHide = this.handleHide.bind(this);
  }

  handleHide() {
    this.setState({ show: false });
    Cookies.set(AcceptCookies.COOKIE_KEY, AcceptCookies.COOKIE_VALUE, 365);
  }

  componentWillMount() {
    if (Cookies.get(AcceptCookies.COOKIE_KEY) === AcceptCookies.COOKIE_VALUE) {
      this.setState({ show: false });
    }
  }

  render() {
    if (this.state.show) {
      return (
        <div className="alert text-center accept-cookies" role="alert">
          <b>
            <Translation identifier="accept_cookies:text_1" />
          </b>
          &#x1F36A;
          <Translation identifier="accept_cookies:text_2" />
          <a
            href="https://fr.wikipedia.org/wiki/Cookie_(informatique)"
            target="_blank"
          >
            <Translation identifier="accept_cookies:text_3" />
          </a>
          <button
            type="button"
            onClick={this.handleHide}
            className="btn btn-primary btn-sm acceptcookies"
            aria-label="Close"
          >
            <Translation identifier="accept_cookies:btn" />
          </button>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default AcceptCookies;
