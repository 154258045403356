import React from "react";
import {
  Marker,
  GoogleMap,
  withGoogleMap,
  withScriptjs
} from "react-google-maps";

import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";

import MapMarker from "../components/MapMarker";
import GoogleMapsStyles from "../theme/GoogleMaps";

import Setting from "../utils/Setting";

import "../components/Map.sass";

class OwnProps {
  position: any;
  showHome: boolean = false;
  markers: any;
  zoom: number = 10;
}

class State {}

class Map extends React.Component<OwnProps, State> {
  googleMapURL =
    "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyALnqR7mUx5_s8OOSavOgsyLSUxHUWovyI";
  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
  }

  render() {
    const MyGoogleMap = withScriptjs(
      withGoogleMap(() => (
        <GoogleMap
          defaultCenter={this.props.position}
          defaultZoom={this.props.zoom}
          options={{ disableDefaultUI: false, styles: GoogleMapsStyles }}
        >
          {this.props.markers.map((marker: any, i: any) => {
            return <MapMarker key={i} marker={marker} />;
          })}

          <MarkerClusterer enableRetinaIcons gridSize={10}>
            {this.props.showHome ? (
              <Marker
                icon={Setting.getFromIdentifier("google_pin_position")}
                position={this.props.position}
              />
            ) : (
              ""
            )}
          </MarkerClusterer>
        </GoogleMap>
      ))
    );
    const loadingElement = <div />;
    const containerElement = (
      <div className="map-container" style={{ height: "50vh" }} />
    );
    const mapElement = (
      <div className="map-element" style={{ height: "50vh" }} />
    );
    const map = (
      <MyGoogleMap
        loadingElement={loadingElement}
        containerElement={containerElement}
        googleMapURL={this.googleMapURL}
        mapElement={mapElement}
      />
    );
    return map;
  }
}

export default Map;
