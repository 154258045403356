import { connect } from "react-redux";
import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { updateBreadcrumb } from "../components/actions/BreadcrumbActions";

import Utils from "../utils/Utils";
import "../components/HomeCategory.sass";

class OwnProps {
  updateBreadcrumb: any;
  category: any = {};
}

class State {
  errors: [] = [];
}

class HomeCategory extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
  }

  onNavLinkClick() {
    this.props.updateBreadcrumb();
    Utils.animateTop();
  }

  render() {
    return (
      <div
        className="home-category justify-content-center mb-3"
        key={this.props.category.id}
      >
        <Card style={{ backgroundColor: this.props.category.category_color }}>
          <Link
            onClick={this.onNavLinkClick.bind(this)}
            to={this.props.category.public_url}
          >
            {this.props.category.image && (
              <Card.Img
                variant="top"
                src={this.props.category.logo_svg_white}
              />
            )}

            <Card.Body>
              <Card.Title>{this.props.category.title}</Card.Title>
              <Card.Text>{this.props.category.intro}</Card.Text>
            </Card.Body>
          </Link>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapActionsToProps = {
  updateBreadcrumb: updateBreadcrumb
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(HomeCategory);
HomeCategory;
