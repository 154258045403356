import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";

library.add(faMobileAlt);

import Cookies from "../utils/Cookies";
import { Container } from "react-bootstrap";
import Translations from "../utils/Translations";
import Setting from "../utils/Setting";

import "../components/RedirectBrowser.sass";

class OwnProps {}

class State {
  show = false;
  url = "";
  message = "";
}

class RedirectBrowser extends React.Component<OwnProps, State> {
  static COOKIE_KEY = "redirect-browser";
  static COOKIE_VALUE = "yes";

  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();

    this.handleHide = this.handleHide.bind(this);
  }

  handleHide() {
    this.setState({ show: false });
    Cookies.set(RedirectBrowser.COOKIE_KEY, RedirectBrowser.COOKIE_VALUE, 1);
  }

  componentWillMount() {
    let message;
    let url;
    let isAppNeeded = false;

    const isIOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    const isAndroid =
      !!navigator.platform && /Android/.test(navigator.platform);

    if (isIOS) {
      message = Translations.getFromIdentifier("redirect-browser:message-ios");
      url = Setting.getStores("app_store_url");
      isAppNeeded = true;
    }

    if (isAndroid) {
      message = Translations.getFromIdentifier(
        "redirect-browser:message-android"
      );
      url = Setting.getStores("google_play_url");
      isAppNeeded = true;
    }

    if (isAppNeeded) {
      if (
        Cookies.get(RedirectBrowser.COOKIE_KEY) !== RedirectBrowser.COOKIE_VALUE
      ) {
        this.setState({ show: true, message, url });
      }
    }

    //this.setState({ show: true });
  }

  render() {
    if (this.state.show) {
      return (
        <Container className="redirect-browser">
          <div className="alert alert-warning text-center" role="alert">
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={this.handleHide}
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <a href={this.state.url}>
              <FontAwesomeIcon icon="mobile-alt" />
            </a>

            <p>
              {this.state.message}

              <hr />

              <a className="btn btn-primary btn-sm" href={this.state.url}>
                {Translations.getFromIdentifier("redirect-browser:btn")}
              </a>
            </p>
          </div>
        </Container>
      );
    } else {
      return null;
    }
  }
}

export default RedirectBrowser;
