import { connect } from "react-redux";
import React from "react";
import RestClient from "../utils/RestClient";
import { Row, Col } from "react-bootstrap";

import Utils from "../utils/Utils";
import { NavLink } from "react-router-dom";
import { updateBreadcrumb } from "../components/actions/BreadcrumbActions";

import "../components/Subcategory.sass";

class OwnProps {
  subcategory: any;
  updateBreadcrumb: any;
}

class State {}

class Subcategory extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
  }

  onNavlinkClicked() {
    this.props.updateBreadcrumb();
    Utils.animateTop();
  }

  render() {
    const subcategory = this.props.subcategory;

    return (
      <div
        className="subcategory"
        style={{
          backgroundColor: subcategory.category__category_color,
          borderColor: subcategory.category__category_color
        }}
      >
        <NavLink
          onClick={this.onNavlinkClicked.bind(this)}
          to={subcategory.public_url}
        >
          <Row>
            <Col className="col-xl-4 col-lg-4 col-sm-4 col-4 col-left">
              <img alt={subcategory.title} src={subcategory.image_square} />
            </Col>
            <Col className="col-xl-8 col-lg-8 col-sm-8 col-8 col-right">
              <NavLink
                onClick={this.onNavlinkClicked.bind(this)}
                to={subcategory.public_url}
              >
                <p className="subcategory_title">{subcategory.title}</p>
                <p className="category_title hidden-xs">
                  {subcategory.category__title}
                </p>
              </NavLink>
            </Col>
          </Row>
        </NavLink>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapActionsToProps = {
  updateBreadcrumb: updateBreadcrumb
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Subcategory);
Subcategory;
