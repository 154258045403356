import React from "react";
import { Container, Jumbotron } from "react-bootstrap";
import AppVersion from "../utils/AppVersion";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi } from "@fortawesome/free-solid-svg-icons";

library.add(faWifi);

import "../components/InvalidConnexion.sass";

class OwnProps {}

class State {}

class InvalidConnexion extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    location.reload();
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    const appversion = AppVersion.VERSION;

    return (
      <div className="invalid-connexion">
        <Container className="text-center mt-3">
          <Jumbotron>
            <FontAwesomeIcon icon="wifi" />
            <h3>Aucune connexion internet</h3>
            <br />
            <button className="btn btn-primary" onClick={this.handleClick}>
              Rafraichir
            </button>
          </Jumbotron>
        </Container>
      </div>
    );
  }
}

export default InvalidConnexion;
