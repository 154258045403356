import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCog } from "@fortawesome/free-solid-svg-icons";

library.add(faCog);

import { Button } from "react-bootstrap";
import RestClient from "../utils/RestClient";
import Translations from "../utils/Translations";
import SettingsModal from "./SettingsModal";

import Utils from "../utils/Utils";

import "../components/SettingsButton.sass";

class OwnProps {
  label?: string;
  type: string = "button";
}

class State {
  status: boolean = false;
  show: boolean = false;
}

class SettingsButton extends React.Component<OwnProps, State> {
  modal: any = null;
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
    this.client = new RestClient();
    this.handleShow = this.handleShow.bind(this);
    this.modal = React.createRef();
  }

  componentDidUpdate() {}

  handleShow() {
    Utils.closeNavigation();
    this.modal.current.refreshData();
  }

  render() {
    let label;

    if (this.props.label) {
      label = this.props.label;
    } else {
      label = Translations.getFromIdentifier("settings-button:title");
    }

    let opener;

    switch (this.props.type) {
      case "button": {
        opener = (
          <Button variant="primary" onClick={this.handleShow}>
            {label}
          </Button>
        );
        break;
      }
      case "navlink": {
        opener = (
          <a className="settings-button nav-link" onClick={this.handleShow}>
            <span className="d-none d-sm-block">
              <FontAwesomeIcon icon="cog" />
              &nbsp;{label}
            </span>
          </a>
        );
        break;
      }

      case "link":
      default: {
        opener = (
          <a className="settings-button" onClick={this.handleShow}>
            {label}
          </a>
        );
        break;
      }
    }

    return (
      <div className="settings-button">
        {opener}
        <SettingsModal ref={this.modal} />
      </div>
    );
  }
}

export default SettingsButton;
