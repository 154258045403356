import React from "react";
import { Dropdown, Row, Col, Pagination } from "react-bootstrap";

import Place from "../components/Place";
import Translation from "../components/Translation";
import Translations from "../utils/Translations";
import Utils from "../utils/Utils";

import "../components/PlacesTable.sass";

class OwnProps {
  places: any;
  navigation: any;
  parent: any;
}

class State {}

class PlacesTable extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
    this.onPrevPageClick = this.onPrevPageClick.bind(this);
    this.onNextPageClick = this.onNextPageClick.bind(this);
  }

  onPrevPageClick() {
    Utils.animateTop();
    this.props.parent.refreshPage(this.props.navigation.prev);
  }

  onNextPageClick() {
    Utils.animateTop();
    this.props.parent.refreshPage(this.props.navigation.next);
  }

  onOrderingClick(ordering: any) {
    Utils.animateTop();
    this.props.parent.refreshPage(1, ordering);
  }

  private _renderNavigation() {
    if (this.props.navigation.prev || this.props.navigation.next) {
      let items = [];

      if (this.props.navigation.prev) {
        items.push(
          <Pagination.Item
            key="navigation_prev"
            onClick={this.onPrevPageClick}
            active={false}
          >
            &lt;
          </Pagination.Item>
        );
      }

      if (this.props.navigation.current) {
        items.push(
          <Pagination.Item
            key="navigation_current"
            onClick={this.onPrevPageClick}
            active={true}
          >
            {this.props.navigation.current}
          </Pagination.Item>
        );
      }

      if (this.props.navigation.next) {
        items.push(
          <Pagination.Item
            key="navigation_next"
            onClick={this.onNextPageClick}
            active={false}
          >
            &gt;
          </Pagination.Item>
        );
      }

      return (
        <div className="places-table">
          <Row>
            <Col className="col-12 col-sm-4 col-results">
              <strong>{this.props.navigation.total}</strong>
              &nbsp;
              {Translations.getFromIdentifier(
                "place-table:" +
                  (this.props.navigation.total > 1 ? "items" : "item")
              )}
            </Col>
            <Col className="col-12 col-sm-4 col-filter">
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {Translations.getFromIdentifier(
                    "place-table:ordering-" +
                      this.props.navigation.ordering.selected
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.props.navigation.ordering.list.map(
                    (ordering: any, i: any) => {
                      return (
                        <Dropdown.Item
                          onClick={() => this.onOrderingClick(ordering)}
                          key={i}
                        >
                          {Translations.getFromIdentifier(
                            "place-table:ordering-" + ordering
                          )}
                        </Dropdown.Item>
                      );
                    }
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className="col-12 col-sm-4 col-pagination">
              <Pagination className="float-right">{items}</Pagination>
              <br />
            </Col>
          </Row>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    if (this.props.places.length > 0) {
      return this._renderPlaces();
    } else {
      return <Translation identifier="places-table:empty" />;
    }
  }

  _renderPlaces() {
    return (
      <div className="places-table">
        {this._renderNavigation()}

        <Row className="places-table-results">
          {this.props.places.map((place: any, key: any) => {
            return (
              <Col key={key} className="col-6">
                <Place place={place} />
              </Col>
            );
          })}
        </Row>

        {this._renderNavigation()}
      </div>
    );
  }
}

export default PlacesTable;
