import React from "react";
import Parser from "html-react-parser";

import RestClient from "../utils/RestClient";
import Translations from "../utils/Translations";
import { isUndefined } from "util";

class OwnProps {
  identifier: string = "";
}

class State {
  data?: { response: any } = undefined;
  loading: boolean = false;
  status: boolean = false;
  errors: [] = [];
}

class Translation extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  iterator = 0;
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
  }

  componentDidMount() {
    this.setState({ loading: true });

    const _this = this;

    const interval = setInterval(function() {
      if (Translations.ready) {
        const data = Translations.getData();
        _this.setState({ data, loading: false });
        clearInterval(interval);
      }
    }, 50);
  }

  componentDidUpdate() {}

  render() {
    let translations;

    if (this.state.loading) {
      return (
        <span
          className="translation loading"
          data-identifier={this.props.identifier}
          key={this.props.identifier}
        >
          Chargement...
        </span>
      );
    } else {
      if (
        this.state.data &&
        this.state.data.response &&
        this.state.data.response.translations
      ) {
        translations = this.state.data.response.translations;
        const identifier = this.props.identifier;
        const translation = translations[identifier];

        if (translation) {
          return (
            <span
              className="translation exists"
              data-identifier={this.props.identifier}
              key={this.props.identifier}
            >
              {Parser(translation)}
            </span>
          );
        } else {
          if (isUndefined(translation)) {
            Translations.setUntranslated(this.props.identifier);
          }

          return (
            <span
              className="translation empty"
              data-identifier={this.props.identifier}
              key={this.props.identifier}
            >
              [{identifier}]
            </span>
          );
        }
      } else {
        if (!this.state.status) {
          return this.client.renderErrors(this.state.errors);
        }
      }
    }
  }
}

export default Translation;
