import { connect } from "react-redux";
import React from "react";
import Parser from "html-react-parser";
import { Row, Col } from "react-bootstrap";
import { updateBreadcrumb } from "../components/actions/BreadcrumbActions";
import { Container, Jumbotron, Spinner } from "react-bootstrap";

import Category from "../components/Category";
import Link from "../components/Link";
import Meta from "../utils/Meta";
import Place from "../components/Place";
import RestClient from "../utils/RestClient";
import Subcategory from "../components/Subcategory";
import Translation from "../components/Translation";

import "../pages/CarouselPage.sass";

class OwnProps {
  updateBreadcrumb: any;
  match?: any = undefined;
}

class State {
  data?: { response: any } = undefined;
  carouselId?: number = 0;
  loading: boolean = false;
  status: boolean = false;
  errors: [] = [];
}

class CarouselPage extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
  }

  async refreshPage(page: any, ordering: string) {
    const carouselId = this.state.carouselId || 0;
    this.refreshData(carouselId, page, ordering);
  }

  async refreshData(
    carouselId: number,
    page: number = 1,
    ordering: string = ""
  ) {
    this.setState({ loading: true });

    let data = await this.client.get("/rest/carousel/" + carouselId + "/", {
      page: page,
      ordering: ordering
    });
    let status: boolean = false;
    let errors = [];

    if (data && data.status) {
      if (data.status == 200) {
        status = true;
      } else {
        if (data.response && data.response.errors) {
          errors = data.response.errors;
        } else {
          errors = [data.response];
        }
      }
    } else {
      errors = ["404"];
    }

    this.setState({ data, carouselId, status, errors, loading: false });
  }

  componentDidMount() {
    let carouselId = this.props.match.params.id;
    this.refreshData(carouselId);
  }

  componentDidUpdate() {
    if (
      this.state.carouselId &&
      this.props.match.params.id &&
      !this.state.loading
    ) {
      if (this.props.match.params.id != this.state.carouselId) {
        let carouselId = this.props.match.params.id;
        this.refreshData(carouselId);
      }
    }
  }

  render() {
    let carousel;

    if (this.state.loading) {
      return (
        <div className="carousel-page">
          <Container>
            <Jumbotron className="text-center loading">
              <Spinner animation="border" role="status">
                <span className="sr-only">...</span>
              </Spinner>
            </Jumbotron>
          </Container>
        </div>
      );
    } else {
      if (
        this.state.data &&
        this.state.data.response &&
        this.state.data.response.item
      ) {
        carousel = this.state.data.response.item;

        Meta.setTitle(carousel.title);

        const jumbotronStyle = {
          backgroundImage: "url(" + carousel.image_slim + ")",
          backgroundColor: carousel.color
        };

        return (
          <div className="carousel-page">
            <Container>
              <Jumbotron style={jumbotronStyle}>
                <h1>{carousel.title}</h1>
                {carousel.intro && (
                  <div>
                    <h2>{carousel.intro}</h2>
                    <div className="clear"></div>
                  </div>
                )}
              </Jumbotron>

              {Parser(carousel.content)}

              {carousel.place && (
                <>
                  <hr />
                  <h5>
                    <Translation identifier="carouselpage:place" />
                  </h5>
                  <Row>
                    <Col className="col-xl-4 col-lg-4 col-sm-6 col-12">
                      <Place place={carousel.place} />
                    </Col>
                  </Row>
                </>
              )}
              {carousel.subcategory && (
                <>
                  <hr />
                  <h5>
                    <Translation identifier="carouselpage:subcategory" />
                  </h5>
                  <Row>
                    <Col className="col-xl-4 col-lg-4 col-sm-6 col-12">
                      <Subcategory subcategory={carousel.subcategory} />
                    </Col>
                  </Row>
                </>
              )}
              {carousel.category && (
                <>
                  <hr />
                  <h5>
                    <Translation identifier="carouselpage:category" />
                  </h5>
                  <Row>
                    <Col className="col-xl-4 col-lg-4 col-sm-6 col-12">
                      <Category category={carousel.category} />
                    </Col>
                  </Row>
                </>
              )}

              {carousel.links.length > 0 && (
                <>
                  <hr />

                  {carousel.links.length == 1 && (
                    <h5>
                      <Translation identifier="carouselpage:link" />
                    </h5>
                  )}

                  {carousel.links.length > 1 && (
                    <h5>
                      <Translation identifier="carouselpage:links" />
                    </h5>
                  )}

                  <Row>
                    {carousel.links.map((link: any, i: any) => {
                      return (
                        <Col
                          className="col-xl-4 col-lg-4 col-sm-6 col-12"
                          key={i}
                        >
                          <Link link={link} />
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}
            </Container>
          </div>
        );
      } else {
        if (!this.state.status) {
          return this.client.renderErrors(this.state.errors);
        } else {
          return null;
        }
      }
    }
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapActionsToProps = {
  updateBreadcrumb: updateBreadcrumb
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(CarouselPage);
CarouselPage;
