import React from "react";
import { connect } from "react-redux";

import {
  Alert,
  Card,
  Spinner,
  Col,
  Container,
  Row,
  Jumbotron
} from "react-bootstrap";

import { NavLink } from "react-router-dom";

import Meta from "../utils/Meta";
import RestClient from "../utils/RestClient";
import { updateBreadcrumb } from "../components/actions/BreadcrumbActions";

import Translation from "../components/Translation";
import Translations from "../utils/Translations";
import Utils from "../utils/Utils";

import "../pages/NotificationsPage.sass";

class OwnProps {
  notifications?: [] = [];
  updateBreadcrumb: any;
}

class State {
  data?: { response: any } = undefined;
  loading: boolean = false;
  status: boolean = false;
  errors: [] = [];
}

class NotificationsPage extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
  }

  async refreshData() {
    this.setState({ loading: true });
    let data = await this.client.get("/rest/notification/", {
      show: true
    });
    let status: boolean = false;
    let errors = [];

    if (data && data.status) {
      if (data.status == 200) {
        status = true;
      } else {
        if (data.response.errors) {
          errors = data.response.errors;
        }
      }
    } else {
      errors = ["404"];
    }

    this.setState({ data, status, errors, loading: false });
  }

  onNavLinkClick() {
    this.props.updateBreadcrumb();
    Utils.animateTop();
  }

  componentDidMount() {
    Meta.setTitle(Translations.getFromIdentifier("notifications-page:title"));
    this.refreshData();
  }

  render() {
    let notifications;

    if (this.state.loading) {
      return (
        <div className="notifications-page">
          <Container>
            <Jumbotron className="text-center loading">
              <Spinner animation="border" role="status">
                <span className="sr-only">...</span>
              </Spinner>
            </Jumbotron>
          </Container>
        </div>
      );
    } else {
      if (
        this.state.data &&
        this.state.data.response &&
        this.state.data.response.notifications
      ) {
        notifications = this.state.data.response.notifications;

        return (
          <Container className="notifications-page">
            <Row>
              {notifications.map((notification: any, i: any) => {
                return (
                  <Col className="col-md-4 col-12" key={i}>
                    <Card className="notification-item">
                      {notification.image_slim && (
                        <NavLink
                          onClick={this.onNavLinkClick.bind(this)}
                          to={notification.public_url}
                        >
                          <Card.Img
                            variant="top"
                            src={notification.image_slim}
                          />
                        </NavLink>
                      )}

                      <Card.Body>
                        <Card.Title className="title">
                          {notification.title}

                          {notification.type.svg && (
                            <img
                              className="type"
                              src={notification.type.svg}
                              alt={notification.type.label}
                            />
                          )}
                          <p className="intro">{notification.intro}</p>
                        </Card.Title>

                        <NavLink
                          onClick={this.onNavLinkClick.bind(this)}
                          className="btn btn-primary"
                          to={notification.public_url}
                        >
                          <Translation identifier="notifications-page:show_btn" />
                        </NavLink>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}

              {notifications.length === 0 ? (
                <Container>
                  <Alert variant="warning">
                    <Translation identifier="notifications-page:empty" />
                  </Alert>
                </Container>
              ) : (
                ""
              )}
            </Row>
          </Container>
        );
      } else {
        if (!this.state.status) {
          return this.client.renderErrors(this.state.errors);
        }
      }
    }
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapActionsToProps = {
  updateBreadcrumb: updateBreadcrumb
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(NotificationsPage);
NotificationsPage;
