import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BreadcrumbReducer from "./reducers/BreadcrumbReducer";
import GeolocationReducer from "./reducers/GeolocationReducer";

import AppVersion from "./utils/AppVersion";
import AboutPage from "./pages/AboutPage";
import CategoryPage from "./pages/CategoryPage";
import SubcategoryPage from "./pages/SubcategoryPage";
import ContactPage from "./pages/ContactPage";
import Footer from "./components/Footer";
import SearchPage from "./pages/SearchPage";
import HomePage from "./pages/HomePage";
import PrivacyPage from "./pages/PrivacyPage";
import NotFoundPage from "./pages/NotFoundPage";
import Header from "./components/Header";
import Breadcrumb from "./components/Breadcrumb";
import InvalidAppVersion from "./components/InvalidAppVersion";
import InvalidConnexion from "./components/InvalidConnexion";
import Session from "./utils/Session";
import NotificationsPage from "./pages/NotificationsPage";
import NotificationPage from "./pages/NotificationPage";
import CarouselPage from "./pages/CarouselPage";
import Translations from "./utils/Translations";
import Setting from "./utils/Setting";

import "bootstrap/dist/css/bootstrap.min.css";

import "./theme/variables.sass";
import "./theme/text.sass";
import "./theme/btn.sass";

import "./pages/Layout.sass";

function AppRouter() {
  return (
    <Router>
      <Header />

      <div className="content">
        <Breadcrumb />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/categorie/:id" component={CategoryPage} />
          <Route path="/sous-categorie/:id" component={SubcategoryPage} />
          <Route path="/recherche/:query" component={SearchPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/notifications" component={NotificationsPage} />
          <Route path="/notification/:id" component={NotificationPage} />
          <Route path="/carousel/:id" component={CarouselPage} />
          <Route path="/privacy" component={PrivacyPage} />
          <Route path="/about" component={AboutPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

class Index {
  static async init() {
    let data = await Session.init();

    if (data) {
      if (data.app_version.identifier === AppVersion.IDENTIFIER) {
        await Translations.init();
        await Setting.init();

        if (data) {
          const allReducers = combineReducers({
            geolocation: GeolocationReducer,
            breadcrumb: BreadcrumbReducer
          });

          const w: any = window as any;

          const composeEnhancers: any =
            typeof w === "object" && w.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
              ? w.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
                })
              : compose;

          const enhancer = composeEnhancers(applyMiddleware(thunk));
          const store = createStore(allReducers, enhancer);

          ReactDOM.render(
            <Provider store={store}>
              <AppRouter />
            </Provider>,
            document.getElementById("root")
          );
        }
      } else {
        console.error(
          "Invalid Version: " +
            data.app_version.identifier +
            "<>" +
            AppVersion.IDENTIFIER
        );

        ReactDOM.render(<InvalidAppVersion />, document.getElementById("root"));
      }
    } else {
      ReactDOM.render(<InvalidConnexion />, document.getElementById("root"));
    }
  }
}

Index.init();
