import { connect } from "react-redux";
import React from "react";
import { Badge } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import Setting from "../utils/Setting";
import Translation from "../components/Translation";
import Utils from "../utils/Utils";

import "../pages/NotificationNav.sass";

library.add(faBell);

import RestClient from "../utils/RestClient";
import { updateBreadcrumb } from "./actions/BreadcrumbActions";

class OwnProps {
  updateBreadcrumb: any;
  notifications?: [] = [];
}

class State {
  data?: { response: any } = undefined;
  loading: boolean = false;
  status: boolean = false;
  errors: [] = [];
}

class NotificationNav extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();
  client: RestClient;

  constructor(props: OwnProps) {
    super(props);
    this.client = new RestClient();
    this.state = new State();
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  async refreshData() {
    this.setState({ loading: true });
    let data = await this.client.get("/rest/notification/", {
      show: false
    });
    let status: boolean = false;
    let errors = [];

    if (data && data.status) {
      if (data.status == 200) {
        status = true;
      } else {
        if (data.response.errors) {
          errors = data.response.errors;
        }
      }
    } else {
      errors = ["404"];
    }

    this.setState({ data, status, errors, loading: false });
  }

  handleRefresh() {
    this.setState({ loading: true });
    Utils.closeNavigation();
    this.props.updateBreadcrumb();
  }

  componentDidMount() {
    this.refreshData();
  }

  render() {
    let badge;
    let containerClass = "notification-nav has-0";

    if (
      !this.state.loading &&
      this.state.data &&
      this.state.data.response &&
      this.state.data.response.notifications_length > 0
    ) {
      if (this.state.data.response.notifications_length > 1) {
        containerClass = "notification-nav has-n centered";
      } else {
        containerClass = "notification-nav has-1";
      }

      badge = (
        <>
          <Badge variant="light">
            {this.state.data.response.notifications_length}
          </Badge>
        </>
      );
    } else {
      badge = "";
    }

    const imgSrc = Setting.getSvg("home_notifications");

    return (
      <div
        className={containerClass}
        style={{
          backgroundColor: Setting.getColor("static_default_btn_bg_color_1")
        }}
      >
        <NavLink
          onClick={this.handleRefresh}
          className="nav-link"
          to="/notifications"
          activeClassName="selected"
        >
          <div className="icon">
            <img src={imgSrc} alt="Notifications" />
            {badge}
          </div>

          <div className="text">
            <Translation identifier="notification-nav:title" />
          </div>
        </NavLink>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapActionsToProps = {
  updateBreadcrumb: updateBreadcrumb
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(NotificationNav);
NotificationNav;
