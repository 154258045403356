import React from "react";
import { Container, Jumbotron } from "react-bootstrap";

import Meta from "../utils/Meta";
import Setting from "../utils/Setting";
import Translation from "../components/Translation";
import Translations from "../utils/Translations";

import "../pages/AboutPage.sass";

class OwnProps {}

class State {}

class AboutPage extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
  }

  componentDidMount() {
    Meta.setTitle(Translations.getFromIdentifier("about-page:title"));
  }

  render() {
    const jumbotronStyle = {
      backgroundImage: "url(" + Setting.getImage("static_about") + ")",
      backgroundColor: Setting.getColor("static_default_btn_bg_color_3")
    };

    return (
      <Container className="about-page">
        <Jumbotron style={jumbotronStyle}>
          <h1>
            <Translation identifier="about-page:title" />
          </h1>
          <h2>
            <Translation identifier="about-page:intro" />
          </h2>
        </Jumbotron>

        <Translation identifier="about-page:content" />
      </Container>
    );
  }
}

export default AboutPage;
