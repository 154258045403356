import React from "react";
import Parser from "html-react-parser";
import Link from "../components/Link";
import Translation from "../components/Translation";
import { Tab, Row, Col, Nav } from "react-bootstrap";

class OwnProps {
  category?: any = undefined;
}

class State {}

class CategoryContent extends React.Component<OwnProps, State> {
  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    const category = this.props.category;
    console.log(category.content);

    return (
      <>
        {category.content ? (
          Parser(category.content)
        ) : (
          <Translation identifier="categorypage:empty_content" />
        )}

        {category.blocks.length > 0 ? (
          <Tab.Container
            id="category_tab"
            defaultActiveKey={category.blocks[0].identifier}
          >
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  {category.blocks.map((block: any, i: any) => {
                    return (
                      <Nav.Item key={i}>
                        <Nav.Link eventKey={block.identifier}>
                          {block.title}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  {category.blocks.map((block: any, i: any) => {
                    return (
                      <Tab.Pane eventKey={block.identifier} key={i}>
                        {Parser(block.content)}
                      </Tab.Pane>
                    );
                  })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        ) : (
          ""
        )}

        {category.links.length > 0 && (
          <>
            <hr />

            {category.links.length == 1 && (
              <h5>
                <Translation identifier="categorypage:link" />
              </h5>
            )}

            {category.links.length > 1 && (
              <h5>
                <Translation identifier="categorypage:links" />
              </h5>
            )}

            <Row>
              {category.links.map((link: any, i: any) => {
                return (
                  <Col className="col-xl-4 col-lg-4 col-sm-6 col-12" key={i}>
                    <Link link={link} />
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </>
    );
  }
}

export default CategoryContent;
