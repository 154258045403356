import React from "react";
import { Button, Col, Row, Modal, Table, Tabs, Tab } from "react-bootstrap";

import Parser from "html-react-parser";

import Map from "../components/Map";
import RestClient from "../utils/RestClient";
import Translation from "../components/Translation";
import Subcategory from "../components/Subcategory";
import TrackPhone from "../components/TrackPhone";
import TrackWebsite from "../components/TrackWebsite";
import Translations from "../utils/Translations";

import "../components/PlaceModal.sass";

class OwnProps {
  place: any = null;
}

class State {
  show: boolean = false;
  place: any = null;
}

class PlaceModal extends React.Component<OwnProps, State> {
  static ref: any;
  client: RestClient;

  static defaultProps = new OwnProps();

  constructor(props: OwnProps) {
    super(props);
    this.state = new State();
    this.client = new RestClient();
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  async refreshData(url: string) {
    this.setState({ show: false });
    let data = await this.client.get(url, {});
    let status: boolean = false;
    let errors = [];

    if (data && data.status) {
      if (data.status == 200) {
        status = true;
        this.setState({ place: data.response.place, show: true });
      } else {
        if (data.response.errors) {
          errors = data.response.errors;
        }
      }
    } else {
      this.setState({ show: false, place: null });
    }
  }

  show(place: any) {
    this.refreshData(place.url);
  }

  componentDidUpdate() {}

  private _renderGeneral() {
    return (
      <div className="place-modal">
        <Table bordered>
          <tbody>
            <tr>
              <th>
                <Translation identifier="place-modal:address" />
              </th>
              <td>{Parser(this.state.place.address)}</td>
            </tr>

            {this.state.place.phone ? (
              <tr>
                <th>
                  <Translation identifier="place-modal:phone" />
                </th>
                <td>
                  <TrackPhone
                    url={this.state.place.track.phone}
                    stripped={this.state.place.phone_stripped}
                    complete={this.state.place.phone}
                  />
                </td>
              </tr>
            ) : (
              ""
            )}

            {this.state.place.website ? (
              <tr>
                <th>
                  <Translation identifier="place-modal:website" />
                </th>
                <td>
                  <TrackWebsite
                    url={this.state.place.track.website}
                    href={this.state.place.website}
                    icon={false}
                  />
                </td>
              </tr>
            ) : (
              ""
            )}

            {this.state.place.opening_hours_display ? (
              <tr>
                <th>
                  <Translation identifier="place-modal:open-hours" />
                </th>
                <td>{Parser(this.state.place.opening_hours_display)}</td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </Table>
      </div>
    );
  }

  private _renderMap() {
    const position = this.state.place.position;
    const markers = [
      {
        title: this.state.place.name,
        icon: this.state.place.google_pin_small_url,
        position: position
      }
    ];

    return (
      <div className="mt-3">
        <TrackWebsite
          url={this.state.place.track.google_maps}
          href={this.state.place.google_maps_url}
          label={Translations.getFromIdentifier("place-modal:google-maps")}
          icon={true}
        />

        <hr />
        <Map zoom={16} markers={markers} position={position} />
      </div>
    );
  }

  private _renderPhotos() {
    return (
      <div className="mt-3">
        <Row>
          {this.state.place.photos.map((photo: any, i: any) => {
            return (
              <Col className="col-md-6" key={i}>
                <img
                  className="img-thumbnail mb-3"
                  alt={this.state.place.name}
                  src={photo.photo}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }

  private _renderSubcategories() {
    return (
      <div className="subcategories-container">
        {this.state.place.subcategories.map((subcategory: any, key: any) => {
          return <Subcategory subcategory={subcategory} key={key} />;
        })}
      </div>
    );
  }

  render() {
    if (this.state.show && this.state.place) {
      return (
        <div className="place-modal">
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.place.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs defaultActiveKey="general" id={this.state.place.id}>
                <Tab
                  eventKey="general"
                  title={Translations.getFromIdentifier(
                    "place-modal:tab-general"
                  )}
                >
                  {this._renderGeneral()}
                </Tab>
                <Tab
                  eventKey="map"
                  title={Translations.getFromIdentifier("place-modal:tab-map")}
                >
                  {this._renderMap()}
                </Tab>

                {this.state.place.subcategories.length > 1 && (
                  <Tab
                    eventKey="subcategories"
                    title={Translations.getFromIdentifier(
                      "place-modal:tab-subcategories"
                    )}
                  >
                    {this._renderSubcategories()}
                  </Tab>
                )}

                {this.state.place.subcategories.length == 1 && (
                  <Tab
                    eventKey="subcategories"
                    title={Translations.getFromIdentifier(
                      "place-modal:tab-subcategory"
                    )}
                  >
                    {this._renderSubcategories()}
                  </Tab>
                )}

                {this.state.place.photos && this.state.place.photos.length ? (
                  <Tab
                    eventKey="photos"
                    title={Translations.getFromIdentifier(
                      "place-modal:tab-photos"
                    )}
                  >
                    {this._renderPhotos()}
                  </Tab>
                ) : (
                  ""
                )}
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                <Translation identifier="place-modal:close" />
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    } else {
      return "";
    }
  }
}

export default PlaceModal;
